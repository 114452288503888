<template>
    <div>
        <div v-if="params.colDef.field == 'bloqued'">
            <feather-icon class="text-success" size="20" icon="CheckCircleIcon" v-if="!params.data.bloqued" />
            <feather-icon class="text-danger" size="20" icon="XCircleIcon" v-else />
        </div>
        <div v-if="params.colDef.field == 'apcnam'">
            <feather-icon class="text-success" size="20" icon="CheckCircleIcon" v-if="params.data.apcnam" />
            <feather-icon class="text-danger" size="20" icon="XCircleIcon" v-else />
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style scoped></style>